<template>
  <div>
        <el-card >
          <el-table :data="interimARList" >
            <el-table-column prop="id" label="ID" width="50"></el-table-column>
            <el-table-column prop="serviceProvider" :label="$t('msg.rate.quoteFrom')"></el-table-column>
            <el-table-column prop="client" :label="$t('msg.rate.quoteTo')" ></el-table-column>
            <el-table-column prop="orderSector" :label="$t('msg.rate.quoteType')" ></el-table-column>
            <el-table-column prop="csopName" :label="$t('msg.rate.csopName')" ></el-table-column>
            <el-table-column prop="createTime" :label="$t('msg.rate.createTime')" ></el-table-column>
            <el-table-column prop="payTerm" :label="$t('msg.rate.payTerm')" ></el-table-column>
            <el-table-column prop="shipStatus" :label="$t('msg.account.shipStatus')" ></el-table-column>
            <el-table-column prop="billStatus" :label="$t('msg.account.billStatus')" ></el-table-column>
            <el-table-column fixed="right" :label="$t('msg.rate.Operations')" width="200px">
              <template #default="{row}">
                <el-button type="primary" size="small" @click="getDetail(row)">{{ $t('msg.excel.show') }}</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios'
export default defineComponent({
  name: 'interimAR',
  setup() {
    const router = useRouter()
    const interimARList = ref()
    const curUser = localStorage.getItem('curUser')
    onMounted(() => {
      const param = 'curName=' + curUser
      const reqURL = process.env.VUE_APP_BASE + '/getMyInterimAR'
      axios.post(reqURL, param).then((response) => {
        interimARList.value = response.data
      }).catch((response) => {
        console.log(response)
      })
    })
    const getDetail = row => {
      const myParam = 'myNewId=' + row.id + '&orderSector=' + row.orderSector + '&curName=' + curUser + '&billType=AR'
      router.push(`/Billing/editTemp/${myParam}`)
    }
    return { interimARList, getDetail }
  }
})
</script>
